.banner-container {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 50px;
}

.banner-container img {
  margin-top: 50px;
  width: 90%;
  max-width: auto;
}
