.carshop-container {
  background-color: black;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Jockey One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
}

.subcontainer {
  padding: 20px 40px;
  border-radius: 5px;
  background-color: #222222;
}

.carshop-container h1 {
  font-size: 2rem;
  margin-bottom: 0px;
}

.compra-container {
  display: grid;
  grid-template-columns: 6fr 2fr;
  gap: 20px;
  width: 100%;
  max-width: 800px;
}

.columnaUno {
  border-right: 2px solid white;
  border-radius: 0px;
}

.columnaUno,
.columnaDos {
  padding: 20px;
}

.columnaUno h2,
.columnaDos h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #7e5bec;
  border-bottom: 2px solid white;
}

.columnaUno p,
.columnaDos p {
  font-size: 1rem;
  margin-bottom: 5px;
}

.columnaUno span,
.columnaDos span {
  font-weight: lighter;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button button {
  background-color: #7e5bec;
  color: white;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button button:hover {
  background-color: rgb(193, 70, 70);
}
