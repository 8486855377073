* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #bad5ce;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #33353f;
}

::-webkit-scrollbar-thumb {
  background-color: #adb7be;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  border-radius: 5px;
}
