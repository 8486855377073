.footer-container {
  background-color: rgb(0, 0, 0);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container img {
  width: 156px;
}
