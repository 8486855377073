.navbar-container {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-family: "Jockey One", sans-serif;
  font-weight: 400;
  font-style: normal;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.logo-container {
  flex: 1;
}

.logo-container img {
  width: 156px;
}

.opciones-container ul {
  list-style: none;
  margin-right: 40px;
  padding: 0;
  display: flex;
}

.opciones-container ul li {
  margin-left: 20px;
}

.opciones-container ul li:first-child {
  margin-left: 0;
}

.opciones-container ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.opciones-container ul li a:hover {
  color: #ff0000;
}

@media (max-width: 768px) {
  .opciones-container {
    display: none;
  }
}
